@import "../../variables.modules";

// mobile-first
h1 {
  font-size: 1.5rem; // Smaller font size for mobile
  text-align: center;
}

h2 {
  font-size: 1.25rem; // Smaller font size for mobile
  text-align: center;
}

h3 {
  padding-bottom: 0.5rem;
  font-size: 0.75rem;
}

.hand {
  animation-name: wave;
  animation-duration: 2.5s;
  animation-iteration-count: infinite;
  transform-origin: 70% 70%;
  display: inline-block;
}

.linkHover {
  display: inline-block;
  transition: transform 0.3s ease-in-out;
}

.linkHover:hover {
  animation: tiltHover 0.3s ease-in-out;
}

a {
  color: $dark;
  transition: color 200ms ease;
}

a:hover {
  color: $lightgray;
  transition: color 200ms ease;
}

@keyframes wave {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(14deg);
  }
  20% {
    transform: rotate(-8deg);
  }
  30% {
    transform: rotate(14deg);
  }
  40% {
    transform: rotate(-4deg);
  }
  50% {
    transform: rotate(10deg);
  }
  60% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@keyframes tiltHover {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(-5deg);
  }
  50% {
    transform: rotate(5deg);
  }
  75% {
    transform: rotate(-5deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

// Breakpoints for larger screens
@media only screen and (min-width: 768px) {
  h1 {
    font-size: 2rem;
  } // Larger font size for tablets and up
  h2 {
    font-size: 1.25rem;
  }
}

@media only screen and (min-width: 940px) {
  h1 {
    font-size: 2.5rem;
  } // Larger font size for desktops
  h2 {
    font-size: 2rem;
  }
}

@media only screen and (min-width: 1200px) {
  .avatar {
    width: 40vh; // Adjusted width for large screens
    height: 40vh;
  }
}
