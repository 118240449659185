// Montserrat Alternates
/* @import url("https://fonts.googleapis.com/css2?family=Montserrat+Alternates:wght@300&display=swap");
 */
//Roboto
/* @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;400;700&display=swap");*/

//Helvetica
/* @import url("https://fonts.googleapis.com/css2?family=Helvetica:wght@100;400;700&display=swap");*/

//Inconsolata
/* @import url("https://fonts.googleapis.com/css2?family=Inconsolata:wght@300&family=Work+Sans:wght@300&display=swap");
 */
//Josefin Sans
/* @import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@300&display=swap");
 */
//Space Mono
/* @import url("https://fonts.googleapis.com/css2?family=Space+Mono:wght@700&display=swap"); */

/* @import url("https://fonts.googleapis.com/css2?family=Work+Sans&display=swap"); */

@import url("https://fonts.googleapis.com/css2?family=Syne:wght@700&display=swap");

@import "./variables.modules";

body {
  /* font-family: "Space Mono", monospace; */
  /* font-family: "Work Sans", sans-serif; */
  font-family: "Syne", sans-serif;
  font-size: 1vh;
  width: 100%;
  height: 100%;
  color: $dark;
  background-color: $white;
  box-sizing: border-box;
}

li {
  color: $dark;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a,
a:link,
a:hover,
a:visited,
a:active {
  text-decoration: none;
}

img {
  max-width: 100%;
  /* max-height: 100%; */
}

ul {
  list-style-type: none;
}
